.ant-upload.ant-upload-select {
  height: fit-content !important;
  width: 100% !important;
  margin: 0 !important;
}
.ant-upload-list-item-container {
  width: 100% !important;
}

.ant-input.ant-input-lg.css-dev-only-do-not-override-1k979oh.ant-input-outlined::-webkit-outer-spin-button,
.ant-input.ant-input-lg.css-dev-only-do-not-override-1k979oh.ant-input-outlined::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-table.css-dev-only-do-not-override-1k979oh {
  /* height: 402px;  */
}

.ant-modal-content {
  padding-right: 5px !important;
}

.popupDiv {
  padding-right: 17px;
}

.popupDiv::-webkit-scrollbar {
  width: 7px;
}
.popupDiv::-webkit-scrollbar-thumb {
  background: #001529;
  border-radius: 7px;
}

.popupDiv::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
}

/* / client Detail Page Style / */
.clientDetailTile {
  width: 100%;
  background-color: #e6f4ff;
  padding: 20px 35px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 10px;
}
.siEntity {
  border: 1px solid #1677ff;
  color: #1677ff;
  width: fit-content;
  border-radius: 5px;
  padding: 2px 8px;
}

.spocDetails {
  padding-left: 10px;
}

/* .detailLabel {
    color: rgba(0, 0, 0, 0.6);
  } */

.contactHeading {
  font-size: 20px;
}

.contactDetail {
  width: 1100px;
  align-self: center;
}

.pocDetails::-webkit-scrollbar {
  width: 7px;
}

.pocDetails::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 7px;
}

.pocDetails::-webkit-scrollbar-thumb {
  background: #001529;
  border-radius: 7px;
}

/* .ant-modal{
  width: 700px !important;
}

.ant-moda.css-dev-only-do-not-override-djtmh8{
  width: 700px !important;
} */



.ant-table-column-title:nth-child(3) .ant-table-filter-trigger-container {
  display: none !important;
}

.error {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 12px;
}

.deleteButtonInPOC{
  padding: 7px 7px !important;
}